import React from "react"
import { Col, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

import TestimoniSection from "./testimoni.sc"
import Aos from "aos"
import "aos/dist/aos.css"
import { useRef, useEffect } from "react"

const NewSection = () => {
  const myRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])
  return (
    <>
      <div id="about" className="WS-index-container">
        <div
          className="WS-base base-container-new"
          data-aos="fade-up"
          ref={myRef}
        >
          <Row>
            <Col span={24} className="WS-keuntungan">
              <div className="WS-container-wismart-text">
                <Typography.Text className="subtitle-new">
                  wisSeller
                </Typography.Text>
                <Typography.Text className="WS-title-new">
                  Keuntungan sebagai penjual
                </Typography.Text>
              </div>
            </Col>
            <Col span={20} className="WS-Col-keuntungan">
              <Row className="WS-Row-benefit">
                <Col span={7} className="WS-Col-benefit">
                  <StaticImage
                    alt="WisSeller"
                    placeholder="none"
                    className="WS-icon-benefit"
                    src="../../../resources/img/wisseller/globe.png"
                  />
                  <Typography.Text className="WS-title-benefit">
                    Jangkau konsumen Indonesia yang lebih menyeluruh
                  </Typography.Text>
                  <Typography.Text className="WS-subtitle-benefit">
                    Dengan Wispay anda akan menjangkau konsumen di area yang
                    sulit dijangkau sebelumnya.
                  </Typography.Text>
                </Col>
                <Col span={7} className="WS-Col-benefit-center">
                  <StaticImage
                    alt="WisSeller"
                    placeholder="none"
                    className="WS-icon-benefit"
                    src="../../../resources/img/wisseller/box.png"
                  />
                  <Typography.Text className="WS-title-benefit">
                    Pemasaran didukung oleh outlet offline dan sales WisPay
                  </Typography.Text>
                  <Typography.Text className="WS-subtitle-benefit">
                    Infrastruktur outlet offline dan tenaga sales tersebar di
                    berbagai kota di Indonesia.
                  </Typography.Text>
                </Col>
                <Col span={7} className="WS-Col-benefit">
                  <StaticImage
                    alt="WisSeller"
                    placeholder="none"
                    className="WS-icon-benefit"
                    src="../../../resources/img/wisseller/network.png"
                  />
                  <Typography.Text className="WS-title-benefit">
                    Promosikan produk dengan metode "Beli Bareng"
                  </Typography.Text>
                  <Typography.Text className="WS-subtitle-benefit">
                    Dengan fitur "Beli Bareng" konsumen akan secara aktif
                    mempromosikan produk anda.
                  </Typography.Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <TestimoniSection />
      </div>
    </>
  )
}

export default NewSection
