import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Typography, Drawer } from "antd"
import { Link } from "gatsby"

import "./Layout.less"
import Icon from "resources/img/wispaylogo.svg"
import Menu from "resources/img/hamburger-menu.png"

const Header = ({ headerVariant, headerBackground }) => {
  const [variant, setVariant] = useState(headerVariant)
  const [background, setBackground] = useState(
    headerVariant === "blue" ? "blue" : "normal"
  )
  const [visible, setVisible] = useState(false)

  const listenScrollEvent = useCallback(() => {
    if (window.scrollY > 100) {
      setVariant("normal")
      setBackground(headerBackground)
    } else {
      setVariant(headerVariant)
      setBackground(headerVariant === "blue" ? "blue" : "normal")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={`site-header wisseleer header-${background} ${
        variant === "black" && "text-header-black"
      }`}
    >
      <div className="section-header">
        <Col span={20} className="header-col">
          <Row justify="space-between" align="middle">
            <Col span={4}>
              <Link to="/">
                <img src={Icon} alt="wispay logo" className="icon-header" />
              </Link>
            </Col>
            <Col
              span={4}
              xl={12}
              lg={16}
              md={0}
              sm={0}
              xs={0}
              className="menu-item"
            >
              <Row align="middle">
                <Col style={{ width: "100%", textAlign: "center" }}>
                  <Link to="/penjual/#about">
                    <Typography.Text className="text-header">
                      Keuntungan
                    </Typography.Text>
                  </Link>
                  <Link to="/penjual/#produk">
                    <Typography.Text className="text-header">
                      Testimoni
                    </Typography.Text>
                  </Link>
                  <Link to="/penjual/#benefits">
                    <Typography.Text className="text-header last">
                      Cara Bergabung
                    </Typography.Text>
                  </Link>
                </Col>
              </Row>
            </Col>
            <Col span={8} className="col-download">
              <Row style={{ justifyContent: "flex-end" }}>
                <Col>
                  <Link to="/penjual/#download">
                    <div
                      className={`btn-border-rounded download ${
                        variant !== "black" ? "normal" : "black"
                      }`}
                    >
                      <Typography.Text>Download</Typography.Text>
                    </div>
                  </Link>
                </Col>
                <Col>
                  <a
                    href="https://seller.wis-pay.com/sign-in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className={`btn-border-rounded joinWisSeler ${
                        variant !== "black" ? "normal" : "black"
                      }`}
                    >
                      <Typography.Text>Masuk</Typography.Text>
                    </div>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col
              className="container-icon-menu"
              onClick={() => setVisible(!visible)}
            >
              <img src={Menu} alt="wispay logo" className="icon-menu" />
            </Col>
          </Row>
        </Col>
      </div>
      <Drawer
        title={
          <Col span={4}>
            <Link to="/">
              <img src={Icon} alt="wispay logo" className="icon-header" />
            </Link>
          </Col>
        }
        placement={"left"}
        closable={true}
        onClose={() => setVisible(!visible)}
        visible={visible}
        key={"left"}
        contentWrapperStyle={{ width: "100%" }}
      >
        <div className="text-header-HP container-drawer">
          <Col span={24} className="menu-responsive">
            <Link onClick={() => setVisible(false)} to="/penjual/#about">
              <Typography.Text className="text-header-HP">
                Keuntungan
              </Typography.Text>
            </Link>
          </Col>
          <Col span={24} className="menu-responsive">
            <Link onClick={() => setVisible(false)} to="/penjual/#produk">
              <Typography.Text className="text-header-HP">
                Testimoni
              </Typography.Text>
            </Link>
          </Col>
          <Col span={24} className="menu-responsive">
            <Link onClick={() => setVisible(false)} to="/penjual/#benefits">
              <Typography.Text className="text-header-HP">
                Cara Bergabung
              </Typography.Text>
            </Link>
          </Col>
          <Row className="Row-button-HP">
            <Col span={24}>
              <div>
                <Link onClick={() => setVisible(false)} to="/penjual/#download">
                  <div
                    className={`btn-border-rounded download-HP ${
                      variant !== "black" ? "normal" : "black"
                    }`}
                  >
                    <Typography.Text>Download</Typography.Text>
                  </div>
                </Link>
                <a
                  href="https://seller.wis-pay.com/sign-in"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setVisible(false)}
                >
                  <div
                    className={`btn-border-rounded joinWisSeler-HP ${
                      variant !== "black" ? "normal" : "black"
                    }`}
                  >
                    <Typography.Text>Masuk</Typography.Text>
                  </div>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  )
}

export default Header
