import * as React from "react"
import "resources/style/style.less"
import TopSectionBG from "components/section/penjual/top.sc"
import NewSection from "components/section/penjual/new.sc"
import KeunggulanSection from "components/section/penjual/keunggulan.sc"
import DownloadSection from "components/section/penjual/download.sc"
import Seo from "../components/seo"
import Layout from "components/layout/penjual"
import imageUrl from "resources/img/wispaylogo.svg"

const penjual = () => {
  return (
    <>
      <Seo
        title={"Wisseller home"}
        description={"Belanja Barang Murah Bareng Teman - Teman Dekat Kamu"}
        imageUrl={imageUrl}
      />
      <Layout>
        <TopSectionBG />
        <NewSection />
        <KeunggulanSection />
        <DownloadSection />
      </Layout>
    </>
  )
}

export default penjual
