import React, { useEffect, useRef } from "react"
import { Card, Carousel, Col, Collapse, Row, Typography } from "antd"

import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import { Pagination, Autoplay, Navigation } from "swiper"
import Aos from "aos"
import "aos/dist/aos.css"
import thumbs from "resources/img/wisseller/thumbs-up.svg"
import thumbs2 from "resources/img/wisseller/thumbs-up2.svg"
import thumbs3 from "resources/img/wisseller/thumbs-up3.svg"
import thumbs4 from "resources/img/wisseller/thumbs-up4.svg"

import { CaretRightOutlined } from "@ant-design/icons"
const { Panel } = Collapse
const thumbsIMG = index =>
  index === 1 ? (
    <span>
      <img src={thumbs} className="thumbs" alt="Thumbs" /> STEP 1
    </span>
  ) : index === 2 ? (
    <span>
      <img src={thumbs2} className="thumbs" alt="discount" /> STEP 2
    </span>
  ) : index === 3 ? (
    <span>
      <img src={thumbs3} className="thumbs" alt="discount" /> STEP 3
    </span>
  ) : (
    <span>
      <img src={thumbs4} className="thumbs" alt="tab" /> STEP 4
    </span>
  )

const KeunggulanSection = () => {
  const sliderRef = useRef(null)

  const changeSLide = index => {
    sliderRef?.current?.goTo(index)
  }
  const myRef = useRef()
  const swipperRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
    swipperRef?.current?.swiper?.autoplay?.stop()
  }, [])
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      if (entry.isIntersecting) {
        swipperRef?.current?.swiper?.autoplay?.start()
      } else {
        swipperRef?.current?.swiper?.autoplay?.stop()
        swipperRef?.current?.swiper?.slideTo(3)
      }
    })
    observer.observe(myRef.current)
  }, [])
  return (
    <div id="benefits" className="benefits" data-aos="fade-up" ref={myRef}>
      <div className="base-container-new" style={{ marginTop: 40 }}>
        <Col span={13} className="col-benefits">
          <div className="container-wismart-text">
            <Typography.Text className="WS-subtitle-new">
              Jualan yang seru dan aman
            </Typography.Text>
            <Typography.Text className="WS-title-benefits">
              Cara Bergabung
            </Typography.Text>
          </div>
          <div className="site-card-wrapper">
            <Swiper
              ref={swipperRef}
              spaceBetween={30}
              slidesPerView={3}
              slideToClickedSlide={true}
              // centeredSlides={true}
              autoplay={{ delay: 3000 }}
              loop={true}
              onSlideChangeTransitionStart={swiper =>
                changeSLide(swiper.realIndex)
              }
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              className="WS-Swiper step-regis"
            >
              <Row gutter={14}>
                <SwiperSlide>
                  <Card>
                    <div className="area-section-produk">
                      <img
                        className="img-section-produk"
                        src={thumbs}
                        alt="logo produk"
                      />
                      <Typography.Text className="name-Step">
                        Step 1
                      </Typography.Text>
                    </div>
                    <Typography.Text className="desc-Step">
                      Daftarkan dan buat toko kamu
                    </Typography.Text>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <div className="area-section-produk">
                      <img
                        className="img-section-produk"
                        src={thumbs2}
                        alt="logo produk"
                      />
                      <Typography.Text className="name-Step">
                        Step 2
                      </Typography.Text>
                    </div>
                    <Typography.Text className="desc-Step">
                      Masukan foto produkmu yang menarik
                    </Typography.Text>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <div className="area-section-produk">
                      <img
                        className="img-section-produk"
                        src={thumbs3}
                        alt="logo produk"
                      />
                      <Typography.Text className="name-Step">
                        Step 3
                      </Typography.Text>
                    </div>
                    <Typography.Text className="desc-Step">
                      Terima pesanan - proses dan kirimkan pesanan
                    </Typography.Text>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  <Card>
                    <div className="area-section-produk">
                      <img
                        className="img-section-produk"
                        src={thumbs4}
                        alt="logo produk"
                      />
                      <Typography.Text className="name-Step">
                        Step 4
                      </Typography.Text>
                    </div>
                    <Typography.Text className="desc-Step">
                      Dapatkan bintang dan ulasan seru dari para pembeli
                    </Typography.Text>
                  </Card>
                </SwiperSlide>
              </Row>
            </Swiper>
          </div>
        </Col>
        <Col span={7} className="WS-panel-col">
          <Carousel
            effect="fade"
            ref={ref => {
              sliderRef.current = ref
            }}
            dots={false}
            // loop={true}
          >
            <Col span={24}>
              <div className="container-new-text-area">
                <div className="container-wismart-hero">
                  <StaticImage
                    className="img-phone"
                    placeholder="none"
                    alt="example"
                    src={"../../../resources/img/wisseller/Phone1.png"}
                  />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="container-new-text-area">
                <div className="container-wismart-hero">
                  <StaticImage
                    className="img-phone"
                    placeholder="none"
                    alt="example"
                    src={"../../../resources/img/wisseller/Phone2.png"}
                  />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="container-new-text-area">
                <div className="container-wismart-hero">
                  <StaticImage
                    className="img-phone"
                    placeholder="none"
                    alt="example"
                    src={"../../../resources/img/wisseller/Phone3.png"}
                  />
                </div>
              </div>
            </Col>

            <Col span={24}>
              <div className="container-new-text-area">
                <div className="container-wismart-hero">
                  <StaticImage
                    className="img-phone"
                    placeholder="none"
                    alt="example"
                    src={"../../../resources/img/wisseller/Phone4.png"}
                  />
                </div>
              </div>
            </Col>
          </Carousel>
          <Collapse
            accordion
            bordered={false}
            defaultActiveKey={["1"]}
            className="panel-step"
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 270 : 90} />
            )}
          >
            <Panel header={thumbsIMG(1)} key="1">
              <p className="step-paragraph">Daftarkan dan buat toko kamu</p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/wisseller/Phone1.png"}
              />
            </Panel>
            <Panel header={thumbsIMG(2)} key="2">
              <p className="step-paragraph">
                Pilih harga "Beli Bareng" supaya kamu dapat harga paling murah.
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/wisseller/Phone2.png"}
              />
            </Panel>
            <Panel header={thumbsIMG(3)} key="3">
              <p className="step-paragraph">
                Selesaikan transaksi kamu dengan memilih pembayaran yang kamu
                inginkan.
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/wisseller/Phone3.png"}
              />
            </Panel>
            <Panel header={thumbsIMG(4)} key="4">
              <p className="step-paragraph">
                Dapatkan bintang dan ulasan seru dari para pembeli
              </p>
              <StaticImage
                className="img-phone"
                placeholder="none"
                alt="example"
                src={"../../../resources/img/wisseller/Phone4.png"}
              />
            </Panel>
          </Collapse>
        </Col>
      </div>
    </div>
  )
}

export default KeunggulanSection
