import React from "react"
import { Col, Row, Typography } from "antd"

import Google from "resources/img/download/googlePlay.png"
import website from "resources/img/download/website.png"
import Aos from "aos"
import "aos/dist/aos.css"
import { useRef, useEffect } from "react"

const DownloadSection = () => {
  const myRef = useRef()
  useEffect(() => {
    Aos.init({ duration: 1000 })
  }, [])

  return (
    <div id="download" data-aos="fade-up" ref={myRef}>
      <div className="base-container-download">
        <Col span={24}>
          <div className="container-download">
            <div className="img-container-download" />
            <div className="linear-container-download" />
            <Col span={20} className="content-download-col">
              <Row className="content-download-row">
                <Col span={24}>
                  <Typography.Text className="title-download">
                    Download aplikasi kami dan rasakan pengalaman baru dalam
                    berbelanja.
                  </Typography.Text>
                  <Row className="area-image-download" gutter={16}>
                    <Col justify="center">
                      <a
                        href="https://play.google.com/store/search?q=wispay&c=apps"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={Google}
                          alt="button play store"
                          className="img-download"
                        />
                      </a>
                    </Col>
                    <Col justify="center">
                      <a
                        href="https://seller.wis-pay.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={website}
                          className="img-download"
                          alt="button app store"
                        />
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </div>
    </div>
  )
}
export default DownloadSection
