import React from "react"
import { Affix, BackTop, Layout as AntLayout } from "antd"
import Header from "./Header"
import Footer from "./Footer"
import { StaticImage } from "gatsby-plugin-image"

const Layout = ({ page, children }) => {
  const { Content } = AntLayout
  return (
    <AntLayout>
      <Affix offsetTop={0}>
        <Header page={page} />
      </Affix>
      <Content className="site-main">{children}</Content>
      <BackTop visibilityHeight={1500}>
        <StaticImage
          alt="Outlet app"
          placeholder="none"
          className="img-background-arrow"
          src="../../../resources/img/shape/arrowUp.png"
        />
      </BackTop>
      <Footer />
    </AntLayout>
  )
}

export default Layout
