import React from "react"
import { Col, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"
import ButtonGradient from "components/button/Gradient"
import { Link } from "gatsby"

const TopSectionBG = () => {
  return (
    <>
      <div className="wisseller container-background-top-area">
        <Col span={24} xl={24} lg={24} md={0} sm={0} xs={0}>
          <StaticImage
            className="top-background"
            src="../../../resources/img/wisseller/wisseller-bg-banner.png"
            placeholder="none"
            alt=""
          />
        </Col>
        <Col span={24} xl={0} lg={0} md={24} sm={24} xs={24}>
          <StaticImage
            className="top-background"
            src="../../../resources/img/wisseller/bg-banner-mobile.png"
            placeholder="none"
            alt=""
          />
        </Col>
      </div>

      <div className="top-area">
        <Col span={20}>
          <div className="WS-img-loading" style={{}}>
            <StaticImage
              alt="Outlet app"
              placeholder="none"
              className="img-loading-static"
              src="../../../resources/img/loader.svg"
            />
          </div>
          <div className="top-area-content">
            <Col xl={12} lg={12} md={18} sm={24} xs={24}>
              <div className="container-text-mobile-center">
                <Typography.Text className="WS-text-big-banner">
                  Jualan cara baru dengan pengalaman belanja yang lebih seru
                  <div className="WS-img-sunrise">
                    <StaticImage
                      alt="Outlet app"
                      placeholder="none"
                      className="WS-img-sunrise-static"
                      src="../../../resources/img/wisseller/money.png"
                    />
                  </div>
                </Typography.Text>
              </div>

              <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="WS-container-text-normal-banner">
                  <Typography.Text className="WS-text-normal-banner">
                    Pengalaman baru dalam berjualan dan dapatkan beragam
                    keuntungan menjadi seller di Wispay.
                  </Typography.Text>
                </div>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <Row className="button-top">
                  <ButtonGradient
                    variant="white-linear"
                    title="Download"
                    href="#download"
                  />
                  <ButtonGradient
                    variant="white-Border"
                    title="Masuk"
                    href="https://seller.wis-pay.com/"
                    target="_blank"
                  />
                </Row>
              </Col>
            </Col>
            <div className="img-area">
              <StaticImage
                alt="Outlet app"
                placeholder="none"
                className="WS-img-background-mockup"
                src="../../../resources/img/wisseller/WS-mokup.png"
              />
              <StaticImage
                alt="Outlet app"
                placeholder="none"
                className="WS-img-background-mockup-HP"
                src="../../../resources/img/wisseller/WS-mokup.png"
              />
            </div>
          </div>
        </Col>
        <div className="img-arrow-down" style={{ cursor: "pointer" }}>
          <Link to="/penjual/#about">
            <StaticImage
              alt="Outlet app"
              placeholder="none"
              className="img-background-arrow"
              src="../../../resources/img/shape/arrowDown.png"
            />
          </Link>
        </div>
      </div>
    </>
  )
}

export default TopSectionBG
